@import '_fonts';
@import '_colors';

@font-face {
  font-family: Braille;
  src: url('../assets/fonts/Braille1.ttf');
}

@font-face {
  font-family: Damgram;
  src: url('../assets/fonts/Damgram.ttf');
}

@font-face {
  font-family: Mortina;
  src: url('../assets/fonts/Mortina.otf');
}

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  background-color: $background-color;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

#root {
  overflow-x: hidden;
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}

h1 {
  @include title-text;

  color: $main-color;
  font-size: 5em;
  line-height: 0.9em;
  margin: 20px 20px 20px 0;
}

h2 {
  @include body-text;

  color: $main-color;
  font-size: 1.2em;
}

p {
  @include body-text;

  color: $main-color;
  font-size: 1em;
}

caption {
  @include caption-text;

  color: $main-color;
  font-size: 1.2em;
  line-height: 0.9em;
  margin: 20px 20px 0 0;
  padding-bottom: 20px;
}

td {
  color: $main-color;
}

a {
  color: $main-light-color;
}

table {
  width: 90%;
  border: 0.1rem solid $main-color;
  border-collapse: collapse;
}

table thead tr th {
  background-color: $main-color;
  color: $text-light-color;
  padding: 0.4em;
  text-transform: capitalize;
}

table > tbody > tr:hover {
  background-color: $background-light-color;
  border: 1px solid $background-color;
}

table > tbody > tr > td,
table > tfoot > tr > td {
  border: 1px solid $background-color;
  padding: 0.4em;
}

table > tr > td:nth-child(3n+1) {
  font-style: italic;
}

table > tr > td:nth-child(2n),
table > tr > td:nth-child(3n) {
  text-align: right;
}

@media (max-width: 798px) {
  h1 {
    margin: 50px auto;
    font-size: 3em;
    text-align: center;
    width: 100%;
  }

  table {
    width: 100%;
  }
}
