@import '../../styles/colors';
@import '../../styles/fonts';

.chaos {
  @include body-text;

  display: flex;
  flex-direction: column;
  font-size: 1em;
  color: $main-color;

  .horizontal-slider {
    width: 100%;
    height: 30px;
    max-width: 300px;
  }

  .hue-shift-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: $text-light-color;
    border: 5px solid $inverted-main-color;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  }

  .hue-shift-track {
    position: relative;
    background: $text-light-color;
  }

  .hue-shift-track.hue-shift-track-0 {
    background: $inverted-main-color;
  }

  .horizontal-slider .hue-shift-track {
    top: 20px;
    height: 4px;
  }

  .horizontal-slider .hue-shift-thumb {
    top: 12px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
  }
}
