@import '../../styles/colors';
@import '../../styles/fonts';

.links {
  @include body-text;

  display: flex;
  flex-direction: column;
  font-size: 1em;
  color: $main-color;

  a {
    text-decoration: none !important;
    color: $main-color;
    margin: 0 5px;
    transition: 0.5s;

    &:hover {
      color: $text-light-color;
    }
  }
}
