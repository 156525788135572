@import '../../styles/fonts';
@import '../../styles/colors';

.body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-shadow: inset 0 0 0 10px $main-color;
  box-shadow: inset 0 0 0 10px $main-color;
  box-shadow: inset 0 0 0 10px $main-color;
}

.title {
  @include title-text;

  color: $main-color;
  font-size: 14.5em;
  line-height: 0.8em;
  margin: 20px 20px 0;
}

.subtitle {
  @include title-text;

  color: $main-color;
  font-size: 2em;
  line-height: 0.9em;
  margin-left: 20px;
  padding-left: 10px;
  letter-spacing: 1px;
}

.content {
  margin-left: 30px;
}

@media (max-width: 798px) {
  .title {
    text-align: center;
    margin: 20px auto;
    font-size: 3em;
    margin-bottom: 0;
    margin-top: 150px;
  }

  .subtitle {
    display: none;
  }
}
