@import '../../styles/colors';

.cursor-circle {
  position: fixed;
  background-color: white;
  width: 10px;
  height: 10px;
  left: -10px;
  top: -10px;
  border-radius: 100%;
  z-index: 10000;
  transform: scale(1);
  transform-style: preserve-3d;
  mix-blend-mode: difference;

  &.disabled {
    display: none;
  }
}

.randy-head {
  position: absolute;
  width: 120px;
  height: 120px;
  left: -60px;
  top: -60px;
  border-radius: 100%;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: scale(1);
  transform-style: preserve-3d;
  overflow: hidden;

  &.disabled {
    display: none;
  }
}

.stellar-cursor {
  pointer-events: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  cursor: none;
}

.toggle-cursor {
  position: fixed;
  bottom: 100px;
  right: 100px;
}

@media (max-width: 800px),
  (max-height: 500px) {
  .toggle-cursor {
    display: none;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch #slider-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  transition: 0.4s;
  transition: 0.4s;
  z-index: 12000;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  transition: 0.4s;
}

#slider-checkbox:checked + .slider {
  background-color: $main-color;
}

#slider-checkbox:checked + .slider:before {
  transform: translateX(26px);
  transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-hint {
  position: absolute;
  width: 50px;
  top: -50px;
}
